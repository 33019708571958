import config from "../config";
const ossUrl = config.ossUrl;

export const languages = [
	{
		label: "English",
		value: "en",
		img: ossUrl + "uk.png",
	},
	{
		label: "中文",
		value: "zh",
		img: ossUrl + "cn.png",
	},
	{
		label: "中文-繁體",
		value: "tw",
		img: ossUrl + "tw.png",
	},
	{
		label: "Tiếng Việt",
		value: "vt",
		img: ossUrl + "vtFlag.png",
	},
	{
		label: "日本語",
		value: "jp",
		img: ossUrl + "jpFlag.png",
	},
	{
		label: "ไทย",
		value: "th",
		img: ossUrl + "thFlag.png",
	},
	{
		label: "Indonesia",
		value: "id",
		img: ossUrl + "idFlag.png",
	},
	{
		label: "한국어",
		value: "kr",
		img: ossUrl + "krFlag.png",
	},
];
